import React from 'react';
import { FindASolution } from '../find-a-solution/find-a-solution';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { FindASolutionFragment } from '../../../models/experiencefragments/find-a-solution';
import './smash-find-a-solution-wrapper.scss';

export const SmashFindASolutionWrapper = () => {
    const category = 'landing';
    const [findASolutionContent] = useExperienceContent<FindASolutionFragment>(
        category + '/tab-components',
        'find-a-solution',
        'findasolution',
        undefined,
        false
    );

    return (
        <>
            {findASolutionContent && !findASolutionContent.hide && (
                <div className={'smash-find-a-solution-wrapper'}>
                    <FindASolution
                        content={findASolutionContent}
                        analyticsEventName="smash-home-articles-onclick-event"
                    />
                </div>
            )}
        </>
    );
};
