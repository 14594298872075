import React from 'react';

interface Props {
    icon: string;
    title: string;
}

export const LinkWithIcon = (props: Props) => {
    return (
        <>
            <span className="search-link-icon">
                <img
                    alt=""
                    src={process.env.REACT_APP_AEM_BASE_URL + props.icon}
                />
            </span>
            <span className="search-link-text">{props.title}</span>
        </>
    );
};
