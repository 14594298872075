import React, { useEffect, useState } from 'react';
import ServerSideService from '../../../../../services/server-side-service/server-side-service';
import {
    AemArticle,
    Article,
} from '../../../../../models/experiencefragments/category-view';
import { PrimaryButton } from '../../../../common/';
import { Heading } from '../../../../common/heading/heading';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { CategoryOnClickCtaInfo } from '../../../../../services/shortcode-service/shortcode-service';
import { NewAnalyticsService } from '../../../../../services/new-analytics-service/new-analytics-service';
import './component-box-5050.scss';

interface Props {
    content: Article | AemArticle;
    eventNames?: string;
}

export const ComponentBox5050 = (props: Article & Props) => {
    const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
        ctaName: props.ctaLabel,
        categoryType: props.categoryType,
        categoryPath: props.categoryPath,
        ctaUrl: props.ctaPath,
    };
    const [fireEvents] = useAnalytics();
    const [backgroundUrl, setBackgroundUrl] = useState<string>('');
    const isMobile = (): boolean => {
        return window.matchMedia('(max-width: 1023px)').matches;
    };
    const handleWindowResize = () => {
        setBackgroundUrl(
            ServerSideService.isClientSide() && isMobile()
                ? encodeURI(props.content.mobileImagePath)
                : encodeURI(props.content.desktopImagePath)
        );
    };
    const eventClick = async () => {
        if (
            props.categoryType == 'category' ||
            props.categoryType == 'subcategory'
        ) {
            const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
                props.ctaPath
            );
            if (referralExit) {
                NewAnalyticsService.fireReferralExitsEvent(
                    fireEvents,
                    referralExit
                );
            } else {
                fireEvents('category-onclick-event', undefined, {
                    categoryOnClickCtaInfo,
                });
            }
        }
    };
    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    return (
        <section className="component-box-5050">
            <div
                style={{
                    backgroundImage: `url(${process.env.REACT_APP_AEM_BASE_URL}${backgroundUrl})`,
                }}
                className="component-box-5050-image"
            />
            <span
                role="img"
                role-img-alt={props.content.imageAltText}
                aria-label={props.content.imageAltText}
            />
            <div className="component-box-5050-body">
                {props.content?.title && (
                    <Heading type="h2" className="component-box-5050-title">
                        {props.content.title}
                    </Heading>
                )}
                {props.content?.headline && (
                    <Heading
                        type={props.content?.title ? 'h3' : 'h2'}
                        className="component-box-5050-subtitle"
                    >
                        {props.content.headline}
                    </Heading>
                )}
                <div className="component-box-5050-description">
                    {props.content.description}
                </div>

                {props.content.ctaLabel && (
                    <PrimaryButton
                        className="component-box-5050-link"
                        href={props.content.ctaPath}
                        ariaLabel={props.content.ctaAriaLabel}
                        color="dark"
                        fill="outline"
                        chevron={true}
                        target={props.content.targetBlank ? '_blank' : ''}
                        onClick={() => eventClick()}
                    >
                        {props.content.ctaLabel}
                    </PrimaryButton>
                )}
            </div>
        </section>
    );
};
