import React, { ReactNode, useEffect, useState } from 'react';
import './masthead.scss';
import ServerSideService from '../../../services/server-side-service/server-side-service';
import { Heading } from '../../common/heading/heading';
import { PrimaryButton } from '../../common';
import { HyperLink } from '../../../models/experiencefragments/smarttiles';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import generateRandomId from '../../utils/generate-random-id/generate-random-id';

interface Props {
    class?: string;
    children?: ReactNode;
    title?: string;
    headline?: string;
    description?: string;
    cta?: HyperLink;
    mobileImg?: string;
    desktopImg?: string;
    dataTestId?: string;
}

export const Masthead = (props: Props) => {
    const [backgroundUrl, setBackgroundUrl] = useState<string>('');

    const isMobile = (): boolean => {
        return window.matchMedia('(max-width: 767px)').matches;
    };

    const handleWindowResize = () => {
        setBackgroundUrl(
            ServerSideService.isClientSide() && isMobile()
                ? encodeURI(props.mobileImg || '')
                : encodeURI(props.desktopImg || '')
        );
    };

    const { currentCountryCode } = new AppConfigurationService();

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <section
            className={`masthead ${props.class ? props.class : ''}`}
            style={{
                backgroundImage: `url(${process.env.REACT_APP_AEM_BASE_URL}${backgroundUrl})`,
            }}
            data-testid={props.dataTestId}
        >
            <div className="masthead__wrapper">
                {props?.title && (
                    <Heading
                        key={generateRandomId()}
                        type="h1"
                        className="masthead__category-title"
                    >
                        {props.title}
                    </Heading>
                )}
                {props?.headline && (
                    <Heading
                        key={generateRandomId()}
                        type={props?.title ? 'h2' : 'h1'}
                        className={`masthead__category-headline ${
                            currentCountryCode === 'vn'
                                ? 'masthead__category-headline-vietnamese'
                                : ''
                        }`}
                    >
                        {props.headline}
                    </Heading>
                )}
                {props?.description && (
                    <div>
                        <p className="masthead__description">
                            {props.description}
                        </p>
                    </div>
                )}
                {props?.cta?.title && (
                    <div className="masthead__cta">
                        <PrimaryButton
                            href={props.cta.url}
                            color="light"
                            fill="outline"
                            chevron={false}
                            ariaLabel={props.cta.ariaLabel}
                            target={props.cta.targetBlank ? '_blank' : ''}
                        >
                            {props.cta.title}
                        </PrimaryButton>
                    </div>
                )}
                {props.children}
            </div>
        </section>
    );
};
